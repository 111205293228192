const publicRoutes = [
    {
        path: '/',
        loader: () => import('./pages/Home.tsx')
    },
    {
        path: '/slots',
        loader: () => import('./pages/Slot.tsx')
    },
    {
        path: '/casino',
        loader: () => import('./pages/Casino.tsx')
    },
    {
        path: '/lobby',
        loader: () => import('./pages/Bingo.tsx')
    },
    {
        path: '/register',
        loader: () => import('./pages/Signup.tsx')
    },
]

const privateRoutes = [
    {
        path: '/my-profile',
        loader: () => import('./pages/Profile.tsx')
    },
    {
        path: '/my-transaction',
        loader: () => import('./pages/Transaction.tsx')
    },
    {
        path: '/balance',
        loader: () => import('./pages/Balance.tsx')
    },
    {
        path: '/pre-buy-tickets',
        loader: () => import('./pages/PreBuyTickets.tsx')
    },
]

export const routes = {
    publicRoutes,
    privateRoutes
}