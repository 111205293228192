import React, { useEffect } from "react";
import { Bounce, ToastContainer } from "react-toastify";
import RoutesComponent from "./view/shared/routes/RoutesComponent.tsx";
import "react-toastify/dist/ReactToastify.css";
import "./present2.css";
import "./template.css";
import "./App.css";
import ConfettiGenerator from "./confetti.js";

const App = () => {
  useEffect(() => {
    const confettiSettings = {
      target: document.getElementById("my-canvas"),
    };
    const confetti = ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, []);

  return (
    <>
      <ToastContainer transition={Bounce} />
      <RoutesComponent />
    </>
  );
};

export default App;
